import Glide from '@glidejs/glide'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    new Glide('.glide', {
      type: 'carousel',
      perView: 5,
      focusAt: 'center',
      peek: 100,
      autoplay: 1500,
      animationDuration: 300,
      breakpoints: {
        576: {
          perView: 1
        },
        768: {
          perView: 2
        },
        992: {
          perView: 3
        },
        1200: {
          perView: 4
        },
        1400: {
          perView: 5
        }
      }
    }).mount()
  }
}