import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "icon","input"]

  connect() {
    this.iconTarget.hidden = true
  }

  checkIconVisibility(){
    if(this.inputTarget.value) {
      this.iconTarget.hidden = false
    } else {
      this.iconTarget.hidden = true
    }
  }

  togglePasswordVisibility() {
    this.iconTarget.classList.toggle("fe-eye");
    this.iconTarget.classList.toggle("fe-eye-off");

    var input_field = this.inputTarget
    if (input_field.type === "password") {
      input_field.type = "text";
    } else {
      input_field.type = "password";
    }
    
  }
}
