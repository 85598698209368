import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "display", "heading" ]

  connect() {
    const t = this

    // display the heading
    t.headingTarget.classList.remove("d-none")

    // Set the date we're counting down to
    // convert unix timestamp: https://stackoverflow.com/a/847196
    var countDownDate = new Date(t.displayTarget.dataset.countdownCutoffValue * 1000).getTime()

    // Update the count down every 1 second
    var x = setInterval(function() {

      // Get today's date and time
      var now = new Date().getTime()

      // Find the distance between now and the count down date
      var distance = countDownDate - now

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24))
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      var seconds = Math.floor((distance % (1000 * 60)) / 1000)

      // Display the result in the element
      t.displayTarget.innerHTML = days + "d " + hours + "h "
      + minutes + "m " + seconds + "s"

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x)
        t.displayTarget.innerHTML = "-"
        t.headingTarget.classList.add("d-none")
      }
    }, 1000)
  }

}
