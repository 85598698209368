import { Controller } from "stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = []

  show() {
    const nutritionInfo = this.element.dataset.nutritionInfo

    const mealBody = document.getElementById('mealInfoModalCard')
    const contentBody = document.getElementById('mealInfoModalContent')

    if (!nutritionInfo) {
      // clear so we don't show exisiting info in there for wrong meal
      mealBody.innerHTML = ''
      contentBody.innerHTML = ''

      return
    }

    const modalHtml = document.getElementById('mealInfoModal')
    const modal = new Modal(modalHtml)

    // set the content
    mealBody.innerHTML = this.element.innerHTML
    contentBody.innerHTML = nutritionInfo
    // Make meal description visible in modal
    mealBody.querySelector('.card-text').classList.remove('d-none')

    // on small screen make the image and title be side by site so 
    // it does not take too much space
    const colImage = mealBody.querySelector('.col-image')
    colImage.classList.remove('col-12')
    colImage.classList.add('col-5', 'col-md-12')

    const imageTag = colImage.querySelector('.meal-image')
    imageTag.classList.remove('card-img-top')
    imageTag.classList.add('img-fluid')

    const colTitle = mealBody.querySelector('.col-title')
    colTitle.classList.remove('col-12')
    colTitle.classList.add('col-7', 'col-md-12')
    mealBody.querySelector('.card-buttons').remove()

    modal.show()
  }

  openSelection(event) {
    const mealType = event.currentTarget.dataset.mealType
    const realInputId = event.currentTarget.dataset.inputId
    const modalTarget = document.getElementById(`${mealType}-meal-change`)
    const realInput = document.getElementById(realInputId)
    const buttonTarget = modalTarget.querySelector(`#meal-${realInput.value}-select-buttons`)
    modalTarget.dataset.inputId = realInputId
    this.resetSelection(modalTarget)
    this.showSelectedMeal(buttonTarget)
    new Modal(modalTarget).show()
  }

  changeMeal(event) {
    const chosenMeal = event.currentTarget.dataset.mealSelectId
    const modalTarget = event.currentTarget.closest('.meal-select-modal')
    const input = document.getElementById(modalTarget.dataset.inputId)
    input.value = chosenMeal
    const mealBody = input.closest('.meal-selection')
    const mealCard = mealBody.querySelector('.meal-card')
    const infoLink = mealBody.querySelector('.info-link')
    const selectedMeal = this.element

    mealBody.querySelector('.col-image').innerHTML = selectedMeal.querySelector('.col-image').innerHTML
    mealBody.querySelector('.name').innerHTML = selectedMeal.querySelector('.name').innerHTML
    mealBody.querySelector('.meal-badges').innerHTML = selectedMeal.querySelector('.meal-badges').innerHTML
    mealCard.dataset.nutritionInfo = this.element.dataset.nutritionInfo

    // hide "details" link visiibility if there is no nutrition info
    if (this.element.dataset.nutritionInfo) {
      infoLink.classList.remove('invisible')
    } else {
      infoLink.classList.add('invisible')
    }

    modalTarget.querySelector('.btn-close').click()

    // add a border for mealBody and fade out after 2 seconds
    mealCard.classList.add('highlighted')
    setTimeout(() => {
      mealCard.classList.remove('highlighted')
    }, 500)
  }

  // Reset all select buttons and remove 'Selected' text
  resetSelection(target) {
    target.querySelector('.meal-selected.d-block')?.classList?.add('d-none')
    target.querySelector('.meal-selected.d-block')?.classList?.remove('d-block')
    target.querySelector('.meal-select.d-none')?.classList?.remove('d-none')
  }

  // Show 'Selected' text for previously selected meal
  showSelectedMeal(buttonTarget) {
    const selectedMealButton = buttonTarget.querySelector('.meal-select')
    const selectedText = buttonTarget.querySelector('.meal-selected')
    selectedMealButton.classList.add('d-none')
    selectedText.classList.remove('d-none')
    selectedText.classList.add('d-block')
  }
}
