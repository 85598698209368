import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["breakfastOrLunchSelect", "breakfastSection", "lunchSection"]

  connect() {
    // if #delivery_breakfast_or_lunch_selection is not present on the page, return
    if (this.hasBreakfastOrLunchSelectTarget) {
      this.breakfastOrLunchUpdated()
    }
  }

  breakfastOrLunchUpdated() {
    // if select target is "breakfast", show breakfast section and hide lunch section
    // if select target is "lunch", show lunch section and hide breakfast section

    const hideClass = "d-none"

    if (this.breakfastOrLunchSelectTarget.value === "breakfast") {
      this.breakfastSectionTarget.classList.remove(hideClass)
      this.lunchSectionTarget.classList.add(hideClass)
    } else if (this.breakfastOrLunchSelectTarget.value === "lunch") {
      this.lunchSectionTarget.classList.remove(hideClass)
      this.breakfastSectionTarget.classList.add(hideClass)
    }

  }
}