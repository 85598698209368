import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.setTimeoutWithTurbolinks(
      function() {
        var bodyClasses = document.body.classList;

        // Counter checks if page has reloaded 10 times
        var subscription_id = localStorage.getItem('subscription_id');
        var reloads_count = localStorage.getItem('checkout_reloads_count');

        // Clears counter if subscription id has changed
        if (reloads_count === null || subscription_id != bodyClasses[2]) {
            reloads_count = 1;
        } else {
            reloads_count++;
        }

        localStorage.setItem("checkout_reloads_count", reloads_count);
        localStorage.setItem("subscription_id", bodyClasses[2]);

        // If counter is more than 3, reloading stopped and manual stripe API request made
        if (reloads_count <= 3) {
          Turbolinks.visit(window.location, { action: 'replace' });
        } else { 
          Turbolinks.visit(window.location.pathname + '?force_fetch=true')
        }
      },
      2000
    );
  }

  setTimeoutWithTurbolinks(timeoutFunction, milliseconds) {
    var timeout = setTimeout(timeoutFunction, milliseconds);

    document.addEventListener("turbolinks:before-cache", function() {
      clearTimeout(timeout);
    });

    document.addEventListener("turbolinks:before-render", function() {
      clearTimeout(timeout);
    });
  }
}
