import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "selectedPlan",
    "planName",
    "numberOfMeals",
    "cost",
    "selectedDuration",
    "deliveryType",
    "deliveryInfo",
    "deliveryRadio",
    "deliveryPrice",
    "deliveryDate",
  ]

  connect() {
    this.updatePricingSummary()
    this.updateDeliveryDates()
  }

  handleChange() {
    this.updatePricingSummary()
    this.updateDeliveryDates()
  }

  updatePricingSummary() {
    var selectedPlanOption = this.selectedPlanTarget.options[this.selectedPlanTarget.selectedIndex]
    var selectedDurationValue = this.selectedDurationTarget.value

    var meals = selectedPlanOption.dataset.meals
    var deliveryCost = selectedPlanOption.dataset.deliveryCost
    var mealsPerDay = JSON.parse(selectedPlanOption.dataset.mealsPerDay)

    // parse boolean from string
    var breakfast_or_lunch = (selectedPlanOption.dataset.breakfastOrLunch === 'true')

    var durationDisplay = 'per week'
    if (selectedDurationValue > 0) {
      durationDisplay += ' for ' + selectedDurationValue + ' week' + ((selectedDurationValue > 1) ? 's' : '')
    }

    // Creates an array of strings with the number of each meal type per week
    const mealsDisplay = [
      !breakfast_or_lunch && mealsPerDay.breakfast > 0 ? `${meals} breakfast` : null,
      !breakfast_or_lunch && mealsPerDay.lunch > 0 ? `${meals} lunches` : null,
      breakfast_or_lunch ? `${meals} breakfasts or lunches` : null,
      mealsPerDay.juice > 0 ? `${meals} juices` : null,
      `${meals} dinners`,
    ].filter(Boolean);

    this.numberOfMealsTarget.innerHTML = mealsDisplay.join(', ') + ' ' + durationDisplay

    var cost = (selectedPlanOption.dataset.cost / 100.00).toFixed(2)
    this.costTarget.innerHTML = `€${cost} ${durationDisplay}`

    this.deliveryPriceTarget.innerHTML = `€${deliveryCost} ` + durationDisplay
    // Update plan name according to selection
    const optionIndex = this.selectedPlanTarget.selectedIndex
    this.planNameTarget.innerHTML = this.selectedPlanTarget.options[optionIndex].text
  }

  changeDeliveryType(event) {
    const checkedInput = event.currentTarget
    if (checkedInput.value == 'collection') {
      this.deliveryTypeTarget.innerHTML = 'Collection'
      this.swapContent(this.deliveryPriceTarget)
      this.swapContent(this.deliveryInfoTarget)
    } else {
      this.deliveryTypeTarget.innerHTML = 'Delivery'
      this.swapContent(this.deliveryPriceTarget)
      this.swapContent(this.deliveryInfoTarget)
    }
  }

  swapContent(element) {
    // Show hidden element and hide the other
    if (element.classList.contains('d-none')) {
      element.classList.remove('d-none')
      element.nextElementSibling.classList.add('d-none')
    } else {
      element.classList.add('d-none')
      element.nextElementSibling.classList.remove('d-none')
    }
  }

  // If sunday delivery dates(weekday warrior), disable non-sunday dates
  updateDeliveryDates() {
    if (this.selectedPlanTarget.value == 'weekday_warrior') {
      // Hide all non-sunday dates

      Array.from(this.deliveryDateTarget.querySelectorAll('option:not([data-day="Sunday"])')).forEach(
        (el) => { 
          el.classList.add('d-none') 
          el.disabled = true
        }
      )

      // Set value of input to first sunday date
      this.deliveryDateTarget.value = this.deliveryDateTarget.querySelector('[data-day="Sunday"]').value
    } else {
      // Unhide all date options
      this.deliveryDateTarget.querySelectorAll('option.d-none').forEach(
        (el) => {
          el.classList.remove('d-none')
          el.disabled = false
        }
      )
    }
  }
}
